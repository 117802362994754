import post_y_icon from "../assets/layouts/post_y_icon.png"
import post_x_icon from "../assets/layouts/post_x_icon.png"
import post_z_lr_icon from "../assets/layouts/post_z_lr_icon.png"
import post_z_rl_icon from "../assets/layouts/post_z_rl_icon.png"

import post_x_icon_green from "../assets/layouts/post_x_icon_green.png"
import post_y_icon_green from "../assets/layouts/post_y_icon_green.png"
import post_z_lr_icon_green from "../assets/layouts/post_z_lr_icon_green.png"
import post_z_rl_icon_green from "../assets/layouts/post_z_rl_icon_green.png"

import post_x_icon_red from "../assets/layouts/post_x_icon_red.png"
import post_y_icon_red from "../assets/layouts/post_y_icon_red.png"
import post_z_lr_icon_red from "../assets/layouts/post_z_lr_icon_red.png"
import post_z_rl_icon_red from "../assets/layouts/post_z_rl_icon_red.png"

import post_x_icon_yellow from "../assets/layouts/post_x_icon_yellow.png"
import post_y_icon_yellow from "../assets/layouts/post_y_icon_yellow.png"
import post_z_lr_icon_yellow from "../assets/layouts/post_z_lr_icon_yellow.png"
import post_z_rl_icon_yellow from "../assets/layouts/post_z_rl_icon_yellow.png"

interface CustomImageElement extends HTMLImageElement {
    filename?: string;
}

const createImage = (src: string, filename: string): CustomImageElement => {
    const img = new Image() as CustomImageElement;
    img.src = src;
    img.filename = filename;
    return img;
};

const y_icon = createImage(post_y_icon, 'post_y_icon');
const x_icon = createImage(post_x_icon, 'post_x_icon');
const z_lr_icon = createImage(post_z_lr_icon, 'post_z_lr_icon');
const z_rl_icon = createImage(post_z_rl_icon, 'post_z_rl_icon');

const y_icon_green = createImage(post_y_icon_green, 'post_y_icon_green');
const x_icon_green = createImage(post_x_icon_green, 'post_x_icon_green');
const z_lr_icon_green = createImage(post_z_lr_icon_green, 'post_z_lr_icon_green');
const z_rl_icon_green = createImage(post_z_rl_icon_green, 'post_z_rl_icon_green');

const y_icon_red = createImage(post_y_icon_red, 'post_y_icon_red');
const x_icon_red = createImage(post_x_icon_red, 'post_x_icon_red');
const z_lr_icon_red = createImage(post_z_lr_icon_red, 'post_z_lr_icon_red');
const z_rl_icon_red = createImage(post_z_rl_icon_red, 'post_z_rl_icon_red');

const y_icon_yellow = createImage(post_y_icon_yellow, 'post_y_icon_yellow');
const x_icon_yellow = createImage(post_x_icon_yellow, 'post_x_icon_yellow');
const z_lr_icon_yellow = createImage(post_z_lr_icon_yellow, 'post_z_lr_icon_yellow');
const z_rl_icon_yellow = createImage(post_z_rl_icon_yellow, 'post_z_rl_icon_yellow');


const dynamicPostIcons = {
  'post_y_icon': y_icon,
  'post_x_icon': x_icon,
  'post_z_lr_icon': z_lr_icon,
  'post_z_rl_icon': z_rl_icon,
  'post_y_icon_green': y_icon_green,
  'post_x_icon_green': x_icon_green,
  'post_z_lr_icon_green': z_lr_icon_green,
  'post_z_rl_icon_green': z_rl_icon_green,
  'post_y_icon_red': y_icon_red,
  'post_x_icon_red': x_icon_red,
  'post_z_lr_icon_red': z_lr_icon_red,
  'post_z_rl_icon_red': z_rl_icon_red,
  'post_y_icon_yellow': y_icon_yellow,
  'post_x_icon_yellow': x_icon_yellow,
  'post_z_lr_icon_yellow': z_lr_icon_yellow,
  'post_z_rl_icon_yellow': z_rl_icon_yellow,
};

export default dynamicPostIcons;