import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
 
const api = new WooCommerceRestApi({
  url: process.env.REACT_APP_WC_URL,
  consumerKey: process.env.REACT_APP_WC_CONSUMER_KEY,
  consumerSecret: process.env.REACT_APP_WC_SECRET_KEY,
  version: "wc/v3"
})

export class ProductService {
    async fetchProducts(){
        const response = api.get('products').then((response) => {
            return response
        })
    }

    async getProducts() {
        const products = await (new ProductService).fetchProducts().then((response) => {
            return response
        })

        return products
        
    }

}